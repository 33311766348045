.projects {
  display: flex;
  position: absolute;
  top: 270vh;
  padding-bottom: 58rem;
  padding-right: 100vw;
  background-color: #3e5874b7;
}

.projects-title {
  color: whitesmoke;
  font-size: 3rem;
  margin-left: 15rem;
  width: 20rem;
}

.projects-list {
  display: grid;
  position: absolute;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 400px);
  grid-gap: 10px;
  list-style: none;
  margin-left: 2rem;
  margin-right: 3rem;
}

.projects-item img {
  display: block;
  aspect-ratio: 1/1;
  object-fit: cover;
  object-position: center;
  transition: 200ms ease-in-out;
  border-radius: 10px;
  border: solid 1px #00fff9;
}

.projects-item div a {
  color: whitesmoke;
  position: absolute;
  font-size: 1.2rem;
  background: #506985cc;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(130px, -280px);
  transition: 200ms ease-in-out;
  text-decoration: none;
}

.projects-item:hover {
  cursor: pointer;
}

.projects-item:hover > img.blur {
  filter: blur(1px);
  transform: scale(1.01);
}

.projects-item > div.fade {
  opacity: 0;
}

.projects-item:hover > div.fade {
  opacity: 1;
}

.projects-click a {
  position: absolute;
  transform: translate(83vw, 86vh);
  color: whitesmoke;
  font-size: 1.5rem;
  text-decoration: none;
}

.projects-click a:hover {
  color: #00fff9;
  transition: 200ms ease-in-out;
}

@media only screen and (min-width: 0px) and (max-width: 413px) {
  .projects {
    top: 355vh;
    padding-bottom: 385vh;
    padding-right: 0;
    left: 0;
  }
  .projects-container {
    left: 0;
    width: 100vw;
  }
  .projects-title {
    font-size: 2.5rem;
    transform: translateX(-210px);
    top: 0;
    left: 0;
  }
  .projects-list {
    display: block;
    margin-left: 0;
    margin-right: 0;
    transform: translateX(-12px);
  }
  .projects-item {
    padding-bottom: 10px;
  }
  .projects-item img {
    width: 320px;
  }
  .projects-item div a {
    display: none;
  }
  .projects-click a {
    transform: translate(200px, 2674px);
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 414px) and (max-width: 500px) {
  .projects {
    top: 303vh;
    padding-bottom: 371vh;
    padding-right: 0;
    left: 0;
  }
  .projects-container {
    left: 0;
    width: 100vw;
  }
  .projects-title {
    font-size: 2.5rem;
    transform: translateX(-196px);
    top: 0;
    left: 0;
  }
  .projects-list {
    display: block;
    margin-left: 0;
    margin-right: 0;
    transform: translateX(-10px);
  }
  .projects-item {
    padding-bottom: 10px;
  }
  .projects-item img {
    width: 350px;
  }
  .projects-item div a {
    display: none;
  }
  .projects-click a {
    transform: translate(225px, 2910px);
    font-size: 1.3rem;
  }
}

@media only screen and (min-width: 501px) and (max-width: 750px) {
  .projects {
    top: 252vh;
    padding-bottom: 128vh;
    padding-right: 150px;
    left: 0;
  }
  .projects-container {
    left: 0;
  }
  .projects-title {
    font-size: 3rem;
    transform: translateX(-50px);
    top: 0;
    left: 0;
  }
  .projects-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 300px);
    column-gap: 2rem;
    row-gap: 2rem;
    margin-left: 25px;
    margin-right: 0;
    transform: translateX(-10px);
  }
  .projects-item {
    padding-bottom: 10px;
  }
  .projects-item img {
    width: 300px;
  }
  .projects-item div a {
    display: none;
  }
  .projects-click a {
    transform: translate(515px, 1325px);
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 751px) and (max-width: 992px) {
  .projects {
    top: 252vh;
    padding-bottom: 145vh;
    padding-right: 510px;
    left: 0;
  }
  .projects-container {
    left: 0;
  }
  .projects-title {
    font-size: 3rem;
    transform: translateX(-27px);
    top: 0;
    left: 0;
  }
  .projects-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 350px);
    column-gap: 2rem;
    row-gap: 2rem;
    margin-left: 6px;
    margin-right: 0;
    transform: translateX(-10px);
  }
  .projects-item {
    padding-bottom: 10px;
  }
  .projects-item img {
    width: 350px;
  }
  .projects-item div a {
    display: none;
  }
  .projects-click a {
    transform: translate(590px, 1520px);
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .projects {
    top: 305vh;
    padding-bottom: 175vh;
    padding-right: 100vw;
    left: 0;
  }
  .projects-container {
    left: 0;
  }
  .projects-title {
    font-size: 3rem;
    margin-left: 3.5rem;
  }
  .projects-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 350px);
    column-gap: 2rem;
    row-gap: 2rem;
    margin-left: 0;
    margin-right: 0;
    transform: translateX(-17px);
  }
  .projects-item {
    padding-bottom: 10px;
  }
  .projects-item img {
    width: 350px;
  }
  .projects-item div a {
    transform: translate(100px, -250px);
  }
  .projects-click a {
    transform: translate(49vw, 71rem);
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) {
  .projects {
    top: 305vh;
    padding-bottom: 194vh;
    padding-right: 100vw;
    left: 0;
  }
  .projects-container {
    left: 0;
  }
  .projects-title {
    font-size: 3rem;
    margin-left: 4.5rem;
  }
  .projects-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 380px);
    column-gap: 2rem;
    row-gap: 2rem;
    margin-left: 0;
    margin-right: 0;
    transform: translateX(-25px);
  }
  .projects-item {
    padding-bottom: 10px;
  }
  .projects-item img {
    width: 380px;
  }
  .projects-item div a {
    transform: translate(118px, -265px);
  }
  .projects-click a {
    transform: translate(50vw, 76.5rem);
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) and (min-height: 600px) and (max-height: 650px) {
  .projects {
    top: 335vh;
    padding-bottom: 210vh;
    padding-right: 100vw;
    left: 0;
  }
  .projects-container {
    left: 0;
  }
  .projects-title {
    font-size: 3rem;
    margin-left: 4.5rem;
  }
  .projects-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 380px);
    column-gap: 2rem;
    row-gap: 2rem;
    margin-left: 0;
    margin-right: 0;
    transform: translateX(-5px);
  }
  .projects-item {
    padding-bottom: 10px;
  }
  .projects-item img {
    width: 380px;
  }
  .projects-item div a {
    transform: translate(118px, -265px);
  }
  .projects-click a {
    transform: translate(50vw, 76.5rem);
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1366px) {
  .projects {
    top: 305vh;
    padding-bottom: 194vh;
    padding-right: 100vw;
    left: 0;
  }
  .projects-container {
    left: 0;
  }
  .projects-title {
    font-size: 3rem;
    margin-left: 4.5rem;
  }
  .projects-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 380px);
    column-gap: 2rem;
    row-gap: 2rem;
    margin-left: 0;
    margin-right: 0;
    transform: translateX(23px);
  }
  .projects-item {
    padding-bottom: 10px;
  }
  .projects-item img {
    width: 380px;
  }
  .projects-item div a {
    transform: translate(118px, -265px);
  }
  .projects-click a {
    transform: translate(50vw, 76.5rem);
  }
}

@media only screen and (min-width: 1367px) and (max-width: 1800px) {
  .projects {
    top: 309vh;
    padding-bottom: 190vh;
    padding-right: 100vw;
    left: 0;
  }
  .projects-container {
    left: 0;
  }
  .projects-title {
    font-size: 3rem;
    margin-left: 4.5rem;
  }
  .projects-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 380px);
    column-gap: 2rem;
    row-gap: 2rem;
    margin-left: 0;
    margin-right: 0;
    transform: translateX(-8px);
  }
  .projects-item {
    padding-bottom: 10px;
  }
  .projects-item img {
    width: 380px;
  }
  .projects-item div a {
    transform: translate(118px, -265px);
  }
  .projects-click a {
    transform: translate(52vw, 76.5rem);
  }
}

@media screen and (min-width: 1820px) and (max-width: 1900px) {
  .projects-click a {
    transform: translate(81vw, 88vh);
  }
}
