.menu-item,
.menuA {
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10%;
  background-color: #032340;
  border-bottom: solid 1px rgba(245, 245, 245, 0.205);
}

.logo {
  cursor: pointer;
  color: rgba(245, 245, 245, 0.849);
}

.menu-list {
  list-style: none;
}

.menu-item {
  display: inline-block;
  padding: 0 20px;
}

.menu-item .menuA {
  transition: all 0.3s ease 0s;
  color: rgba(245, 245, 245, 0.5);
}

.menu-item .menuA:hover {
  color: #0a89a6;
  cursor: pointer;
}

.header-left-side {
  margin-top: 8px;
}

.header-right-side {
  margin-top: 8px;
}

.menu-nav > .menu-toggle {
  display: none;
}

@media (max-width: 782px) {
  .menu-nav > .menu-list {
    position: absolute;
    top: 71px;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    background: #032340;
    left: 0;
    width: 100%;
    height: 600vh;
    transform: translateX(-100%);
    transition: all 0.45s;
    z-index: 1000;
  }
  .menu-nav > .menu-list > .menu-item::before {
    background: transparent;
  }
  .menu-nav > .menu-list.open {
    transform: translateX(0);
  }
  .menu-nav > .menu-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .menu-toggle > .menu-bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: whitesmoke;
  }
  .menu-toggle > .menu-bar::before,
  .menu-toggle > .menu-bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: whitesmoke;
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }
  .menu-toggle > .menu-bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }
  .menu-toggle > .menu-bar::after {
    width: 32px;
    transform: translateY(8px);
  }
  .menu-toggle.open > .menu-bar {
    transform: translateX(-40px);
    background: transparent;
  }
  .menu-toggle.open > .menu-bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }
  .menu-toggle.open > .menu-bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
  .menu-item {
    padding: 20px 20px;
  }
  .menu-item,
  .menuA {
    font-size: 20px;
  }
  .header {
    z-index: 100;
    padding: 0px 10%;
  }
  .contact-menu::after {
    content: "";
    width: 100%;
    border-bottom: solid 1px rgba(245, 245, 245, 0.205);
    position: absolute;
    transform: translate(-150px, 90px);
  }
}

@media only screen and (min-width: 0px) and (max-width: 413px) {
  .menu-list {
    max-width: 90vw;
  }
  .menu-item {
    width: 75vw;
  }
}

@media only screen and (min-width: 414px) and (max-width: 500px) {
  .menu-list {
    max-width: 90vw;
  }
  .menu-item {
    width: 75vw;
  }
}
