.contact {
  display: flex;
  position: absolute;
  top: 385vh;
  padding-bottom: 10rem;
}

.contact-container {
  display: flex;
}

.contact-title {
  color: whitesmoke;
  font-size: 3rem;
  margin-left: 13rem;
}

.contact-text {
  color: whitesmoke;
  font-size: 1.3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: 13rem;
}

.contact-form {
  margin-left: 13rem;
  display: flex;
  flex-direction: column;
}

.inputBox {
  position: relative;
  width: 250px;
}

.inputBox input,
.inputBox textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: #1d2b3a;
  border-radius: 5px;
  outline: none;
  color: whitesmoke;
  font-size: 1em;
  transition: 0.5s;
}

.inputBox span {
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px;
  pointer-events: none;
  font-size: 1em;
  color: rgba(255, 255, 255, 0.25);
  text-transform: uppercase;
  transition: 0.5s;
}

.inputBox input:valid ~ span,
.inputBox input:focus ~ span,
.inputBox textarea:valid ~ span,
.inputBox textarea:focus ~ span {
  color: #00dfc4;
  transform: translateX(10px) translateY(-7px);
  font-size: 0.65em;
  padding: 0 10px;
  background: #1d2b3a;
  border-left: 1px solid #00dfc4;
  border-right: 1px solid #00dfc4;
  letter-spacing: 0.2em;
}

.inputBox:nth-child(2) input:valid ~ span,
.inputBox:nth-child(2) input:focus ~ span,
.inputBox:nth-child(2) textarea:valid ~ span,
.inputBox:nth-child(2) textarea:focus ~ span {
  background: #00dfc4;
  color: #1d2b3a;
  border-radius: 2px;
}

.inputBox input:valid,
.inputBox input:focus,
.inputBox textarea:valid,
.inputBox textarea:focus {
  border: 1px solid #00dfc4;
}

textarea {
  height: 200px;
}

.first-row-box {
  display: flex;
}

.second-row-box {
  margin-bottom: 1rem;
}

.third-row-box {
  margin-bottom: 1rem;
}

.name-box {
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.email-box {
  margin-left: 1rem;
}

.subject-box {
  width: 534px;
}

.message-box {
  width: 534px;
}

.submit-button {
  border: none;
  background: transparent;
  width: 180px;
  height: 55px;
  position: relative;
  margin: 0 15px;
  margin-left: 23rem;
}

.contact-button {
  position: relative;
  display: inline-flex;
  width: 180px;
  height: 55px;
  margin: 0 15px;
  perspective: 1000px;
  /* margin-left: 23rem; */
  margin-top: 1rem;
  transform: translate(-20px, -17px);
}

.contact-button a {
  font-size: 19px;
  letter-spacing: 1px;
  transform-style: preserve-3d;
  transform: translateZ(-25px);
  transition: transform 0.25s;
  font-family: "Saira", sans-serif;
}

.contact-button a:before,
.contact-button a:after {
  position: absolute;
  content: "SEND MESSAGE!";
  height: 55px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #3e5874;
  box-sizing: border-box;
  border-radius: 5px;
}

.contact-button a:before {
  color: whitesmoke;
  background: #3e5874;
  transform: rotateY(0deg) translateZ(25px);
}

.contact-button a:after {
  color: #3e5874;
  transform: rotateX(90deg) translateZ(25px);
}

.contact-button a:hover {
  transform: translateZ(-25px) rotateX(-90deg);
  cursor: pointer;
}

.contact-left-side {
  flex: 50%;
}

.contact-right-side {
  flex: 50%;
  transform: translate(185px, 200px);
}

.contact-right-side ul {
  position: relative;
  transform: skewY(-15deg);
}

.contact-right-side ul li {
  position: relative;
  list-style: none;
  width: 120px;
  background: #3e5874;
  padding: 15px;
  transition: 0.5s;
}

.contact-right-side ul .c1 {
  z-index: 6;
}

.contact-right-side ul .c2 {
  z-index: 5;
}

.contact-right-side ul .c3 {
  z-index: 4;
}

.contact-right-side ul .c4 {
  z-index: 3;
}

.contact-right-side ul .c5 {
  z-index: 2;
}

.contact-right-side ul .c6 {
  z-index: 1;
}

.contact-right-side ul .c1:hover {
  transform: translateX(-50px);
  background: #1877f2;
}

.contact-right-side ul .c2:hover {
  transform: translateX(-50px);
  background: #6e5494;
}

.contact-right-side ul .c3:hover {
  transform: translateX(-50px);
  background: #1da1f2;
}

.contact-right-side ul .c4:hover {
  transform: translateX(-50px);
  background: #c32aa3;
}

.contact-right-side ul .c5:hover {
  transform: translateX(-50px);
  background: #ff0000;
}

.contact-right-side ul .c6:hover {
  transform: translateX(-50px);
  background: #0a66c2;
}

.contact-right-side ul li::before {
  content: "";
  position: absolute;
  top: 0;
  left: -40px;
  width: 40px;
  height: 100%;
  background: #2e4257;
  filter: brightness(0.7);
  transform-origin: right;
  transform: skewY(45deg);
  transition: 0.5s;
}

.contact-right-side ul .c1:hover::before {
  background: #1877f2;
  filter: brightness(0.7);
}

.contact-right-side ul .c2:hover::before {
  background: #6e5494;
  filter: brightness(0.7);
}

.contact-right-side ul .c3:hover::before {
  background: #1da1f2;
  filter: brightness(0.7);
}

.contact-right-side ul .c4:hover::before {
  background: #c32aa3;
  filter: brightness(0.7);
}

.contact-right-side ul .c5:hover::before {
  background: #ff0000;
  filter: brightness(0.7);
}

.contact-right-side ul .c6:hover::before {
  background: #0a66c2;
  filter: brightness(0.7);
}

.contact-right-side ul li::after {
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
  width: 100%;
  height: 40px;
  background: #2e4257;
  filter: brightness(0.9);
  transform-origin: bottom;
  transform: skewx(45deg);
  transition: 0.5s;
}

.contact-right-side ul .c1:hover::after {
  background: #1877f2;
  filter: brightness(0.9);
}

.contact-right-side ul .c2:hover::after {
  background: #6e5494;
  filter: brightness(0.9);
}

.contact-right-side ul .c3:hover::after {
  background: #1da1f2;
  filter: brightness(0.9);
}

.contact-right-side ul .c4:hover::after {
  background: #c32aa3;
  filter: brightness(0.9);
}

.contact-right-side ul .c5:hover::after {
  background: #ff0000;
  filter: brightness(0.9);
}

.contact-right-side ul .c6:hover::after {
  background: #0a66c2;
  filter: brightness(0.9);
}

.contact-right-side ul li a {
  text-decoration: none;
  color: #999;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.contact-right-side ul li:hover a {
  color: #fff;
}

.contact-right-side ul li:last-child::after {
  box-shadow: -120px 120px 20px rgba(0, 0, 0, 0.25);
}

.contact-right-side ul li span {
  position: absolute;
  top: 0;
  left: -40px;
  width: 40px;
  height: 100%;
  transform-origin: right;
  transform: skewY(45deg);
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  font-size: 1.25em;
}

.contact-right-side ul li:hover span {
  opacity: 1;
}

.contact-astro {
  position: absolute;
  transform: translate(290px, -350px);
}

.contact-astro img {
  width: 400px;
  animation: astroAnimate 1s infinite;
}

@keyframes astroAnimate {
  0%,
  100% {
    transform: translateY(-2px);
  }
  25%,
  75% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(2px);
  }
}

@media only screen and (max-width: 768px) {
  .aos-animate {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;

    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;

    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 413px) {
  .contact {
    flex-direction: column;
    top: 760vh;
    padding-bottom: 0;
    width: 100vw;
  }
  .contact-container {
    display: flex;
    flex-direction: column;
  }
  .contact-title {
    font-size: 2.5rem;
    margin-left: 5.1rem;
  }
  .contact-text {
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
  }
  .contact-form {
    margin-left: 1rem;
  }
  .inputBox {
    width: 325px;
  }
  .first-row-box {
    display: block;
  }
  .name-box {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
  .email-box {
    margin-left: 0rem;
    margin-bottom: 1rem;
  }
  .submit-button {
    margin-left: 10.4rem;
  }
  .contact-left-side {
    flex: 0;
  }
  .contact-right-side {
    flex: 0;
    transform: translate(100px, 100px);
    width: 50vw;
  }
  .contact-astro {
    display: none;
  }
}

@media only screen and (min-width: 414px) and (max-width: 500px) {
  .contact {
    flex-direction: column;
    top: 695vh;
    padding-bottom: 0;
    width: 100vw;
  }
  .contact-title {
    font-size: 2.5rem;
    margin-left: 6rem;
  }
  .contact-text {
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    margin-left: 3rem;
    margin-right: 1rem;
    text-align: center;
  }
  .contact-form {
    margin-left: 1rem;
  }
  .inputBox {
    width: 360px;
  }
  .first-row-box {
    display: block;
  }
  .name-box {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
  .email-box {
    margin-left: 0rem;
    margin-bottom: 1rem;
  }
  .submit-button {
    margin-left: 12.5rem;
  }
  .contact-left-side {
    flex: 0;
  }
  .contact-right-side {
    flex: 0;
    transform: translate(-298px, 818px);
  }
  .contact-astro {
    display: none;
  }
}

@media only screen and (min-width: 501px) and (max-width: 750px) {
  .contact {
    flex-direction: column;
    top: 397vh;
    padding-bottom: 0;
  }
  .contact-title {
    font-size: 3rem;
    margin-left: 15rem;
  }
  .contact-text {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    margin-left: 8.8rem;
    margin-right: 0;
    text-align: center;
  }
  .contact-form {
    margin-left: 8.5rem;
  }
  .inputBox {
    width: 450px;
  }
  .first-row-box {
    display: block;
  }
  .name-box {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
  .email-box {
    margin-left: 0rem;
    margin-bottom: 1rem;
  }
  .submit-button {
    margin-left: 18rem;
  }
  .contact-left-side {
    flex: 0;
  }
  .contact-right-side {
    flex: 0;
    transform: translate(-340px, 880px);
  }
  .contact-astro {
    display: none;
  }
}

@media only screen and (min-width: 751px) and (max-width: 992px) {
  .contact {
    flex-direction: column;
    top: 410vh;
    padding-bottom: 0;
  }
  .contact-title {
    font-size: 3rem;
    margin-left: 17rem;
  }
  .contact-text {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    margin-left: 6rem;
    margin-right: 0;
    text-align: center;
  }
  .contact-form {
    margin-left: 4.8rem;
  }
  .inputBox {
    width: 300px;
  }
  .first-row-box {
    display: flex;
  }
  .name-box {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
  .email-box {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  .subject-box {
    width: 632px;
  }
  .message-box {
    width: 632px;
  }
  .submit-button {
    margin-left: 29.6rem;
  }
  .contact-left-side {
    flex: 0;
  }
  .contact-right-side {
    flex: 0;
    transform: translate(-435px, 800px);
  }
  .contact-astro {
    display: none;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .contact {
    top: 510vh;
    padding-bottom: 0;
  }
  .contact-title {
    font-size: 3rem;
    margin-left: 3.5rem;
  }
  .contact-text {
    font-size: 1.3rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 3.5rem;
    margin-right: 4rem;
  }
  .contact-form {
    margin-left: 3.5rem;
  }
  .inputBox {
    width: 250px;
  }
  .email-box {
    margin-left: 1rem;
  }
  .subject-box {
    width: 534px;
  }
  .message-box {
    width: 534px;
  }
  .submit-button {
    margin-left: 23.5rem;
  }
  .contact-left-side {
    flex: 50%;
  }
  .contact-right-side {
    flex: 50%;
    transform: translate(190px, 200px);
  }
  .contact-astro {
    display: none;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1366px) {
  .contact {
    top: 523vh;
    padding-bottom: 0;
  }
  .contact-title {
    font-size: 3rem;
    margin-left: 4rem;
  }
  .contact-text {
    font-size: 1.3rem;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    margin-left: 4rem;
    margin-right: 2rem;
  }
  .contact-form {
    margin-left: 3.5rem;
  }
  .inputBox {
    width: 250px;
  }
  .email-box {
    margin-left: 1rem;
  }
  .subject-box {
    width: 534px;
  }
  .message-box {
    width: 534px;
  }
  .submit-button {
    margin-left: 23.5rem;
    margin-top: 1rem;
  }
  .contact-left-side {
    flex: 50%;
  }
  .contact-right-side {
    flex: 50%;
    transform: translate(230px, 200px);
  }
  .contact-astro {
    display: none;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) and (min-height: 600px) and (max-height: 650px) {
  .contact {
    top: 570vh;
    padding-bottom: 0;
  }
  .contact-title {
    font-size: 3rem;
    margin-left: 4rem;
  }
  .contact-text {
    font-size: 1.3rem;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    margin-left: 4rem;
    margin-right: 2rem;
  }
  .contact-form {
    margin-left: 3.5rem;
  }
  .inputBox {
    width: 250px;
  }
  .email-box {
    margin-left: 1rem;
  }
  .subject-box {
    width: 534px;
  }
  .message-box {
    width: 534px;
  }
  .submit-button {
    margin-left: 23.5rem;
    margin-top: 1rem;
  }
  .contact-left-side {
    flex: 50%;
  }
  .contact-right-side {
    flex: 50%;
    transform: translate(230px, 200px);
  }
  .contact-astro {
    display: none;
  }
}

@media only screen and (min-width: 1367px) and (max-width: 1800px) {
  .contact {
    top: 519vh;
    padding-bottom: 0;
  }
  .contact-title {
    font-size: 3rem;
    margin-left: 4rem;
  }
  .contact-text {
    font-size: 1.3rem;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    margin-left: 4rem;
    margin-right: 2rem;
  }
  .contact-form {
    margin-left: 3.5rem;
  }
  .inputBox {
    width: 250px;
  }
  .email-box {
    margin-left: 1rem;
  }
  .subject-box {
    width: 534px;
  }
  .message-box {
    width: 534px;
  }
  .submit-button {
    margin-left: 23.5rem;
    margin-top: 1rem;
  }
  .contact-left-side {
    flex: 50%;
  }
  .contact-right-side {
    flex: 50%;
    transform: translate(230px, 200px);
  }
  .contact-astro {
    display: none;
  }
}
