.scrollicon {
  transform: translate(95vw, 90vh);
  position: absolute;
}

.scrolldown {
  --color: rgba(245, 245, 245, 0.61);
  --sizeX: 30px;
  --sizeY: 50px;
  position: relative;
  width: var(--sizeX);
  height: var(--sizeY);
  margin-left: var(sizeX / 2);
  border: calc(var(--sizeX) / 10) solid var(--color);
  border-radius: 50px;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.scrolldown::before {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: var(--color);
  border-radius: 100%;
  animation: scrolldown-anim 2s infinite;
  box-sizing: border-box;
  box-shadow: 0px -5px 3px 1px #ffffff66;
}

@keyframes scrolldown-anim {
  0% {
    opacity: 0;
    height: 6px;
  }
  40% {
    opacity: 1;
    height: 10px;
  }
  80% {
    transform: translate(0, 20px);
    height: 10px;
    opacity: 0;
  }
  100% {
    height: 3px;
    opacity: 0;
  }
}
.chevrons {
  padding: 6px 0 0 0;
  margin-left: -3px;
  margin-top: 48px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chevrondown {
  margin-top: -6px;
  position: relative;
  border: solid var(--color);
  border-width: 0 3px 3px 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}
.chevrondown:nth-child(odd) {
  animation: pulse 500ms ease infinite alternate;
}
.chevrondown:nth-child(even) {
  animation: pulse 500ms ease infinite alternate 250ms;
}
@keyframes pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@media only screen and (min-width: 0px) and (max-width: 413px) {
  .scrollicon {
    transform: translate(85vw, 65vh);
    position: absolute;
  }
  .scrolldown::before {
    display: none;
  }
  .scrolldown {
    border: none;
  }
}

@media only screen and (min-width: 414px) and (max-width: 500px) {
  .scrollicon {
    transform: translate(85vw, 60vh);
    position: absolute;
  }
  .scrolldown::before {
    display: none;
  }
  .scrolldown {
    border: none;
  }
}

@media only screen and (min-width: 501px) and (max-width: 750px) {
  .scrollicon {
    transform: translate(90vw, 60vh);
    position: absolute;
  }
  .scrolldown::before {
    display: none;
  }
  .scrolldown {
    border: none;
  }
}

@media only screen and (min-width: 751px) and (max-width: 992px) {
  .scrollicon {
    transform: translate(90vw, 60vh);
    position: absolute;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .scrollicon {
    transform: translate(94vw, 87vh);
    position: absolute;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1800px) {
  .scrollicon {
    transform: translate(94vw, 87vh);
    position: absolute;
  }
}
