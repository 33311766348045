.progressMainWrapper {
  background-color: rgb(31, 31, 31);
  height: 10px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  border-radius: 5px;
  /* display: none; */
}

.progressMainStyle {
  height: 10px;
  background-color: #00fffb9a;
  border-radius: 5px;
}
