.skills {
  display: flex;
  position: absolute;
  top: 180vh;
  padding-left: 15rem;
  padding-right: 18rem;
  padding-bottom: 10rem;
}

.skills-left-container {
  flex: 50%;
}

.skills-right-container {
  flex: 50%;
}

.skills-title {
  color: whitesmoke;
  font-size: 3rem;
}

.skills-text {
  color: whitesmoke;
  font-size: 1.3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.skills-text a {
  color: #00fff9;
  font-size: 1.3rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.skills-wrapper {
  width: 40%;
  min-width: 500px;
  position: absolute;
  transform: translate(-50%, -60%);
  left: 50%;
  top: 50%;
}

.skills-bar-container {
  width: 80%;
  padding: 50px 30px;
  background-color: #3e5874b7;
  border-radius: 10px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  transform: translateX(30rem);
  color: whitesmoke;
}

/* .shape-1 {
  height: 220px;
  width: 220px;
  background: linear-gradient(#ff0e72, #ff66a6);
  border-radius: 50%;
  position: absolute;
  bottom: -100px;
  left: 50px;
  z-index: -1;
}

.shape-2 {
  height: 180px;
  width: 180px;
  background: linear-gradient(#3da5e0, #325ea5);
  border-radius: 50%;
  position: absolute;
  top: -100px;
  right: -50px;
  z-index: -1;
} */

.skills-bar-title {
  margin-bottom: 40px;
  letter-spacing: 2px;
}

.skills-bar:not(:last-child) {
  margin-bottom: 30px;
}

.details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.bar {
  position: relative;
  background-color: rgba(255, 255, 255, 0.219);
  padding: 7px 10px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
}

.bar div {
  position: relative;
  width: 0;
  height: 5px;
  border-radius: 10px;
  background-color: #badefc;
  border: 1px solid #1c97fc;
  box-shadow: 0 0 5px rgba(28, 151, 252, 0.6);
}

#html-bar {
  animation: html-fill 2s forwards;
}

@keyframes html-fill {
  100% {
    width: 90%;
  }
}

#css-bar {
  animation: css-fill 2s forwards;
}

@keyframes css-fill {
  100% {
    width: 85%;
  }
}

#js-bar {
  animation: js-fill 2s forwards;
}

@keyframes js-fill {
  100% {
    width: 72%;
  }
}

#react-bar {
  animation: react-fill 2s forwards;
}

@keyframes react-fill {
  100% {
    width: 68%;
  }
}

@media only screen and (max-width: 768px) {
  .aos-animate {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;

    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;

    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 413px) {
  .skills {
    top: 155vh;
    padding-left: 28px;
    padding-right: 0;
    padding-bottom: 0;
    flex-direction: column;
  }
  .skills-left-container {
    flex: 0;
  }
  .skills-right-container {
    flex: 0;
  }
  .skills-title {
    font-size: 2.5rem;
    text-align: center;
    transform: translateX(-10px);
  }
  .skills-text {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    transform: translateX(-12px);
  }
  .skills-wrapper {
    width: 10%;
    min-width: 375px;
    position: absolute;
    transform: translate(-473px, 900px);
    left: 0;
    top: 0;
  }
  .skills-bar-title {
    margin-bottom: 20px;
    margin-top: 0;
  }
}

@media only screen and (min-width: 414px) and (max-width: 500px) {
  .skills {
    top: 135vh;
    padding-left: 28px;
    padding-right: 0;
    padding-bottom: 0;
    flex-direction: column;
  }
  .skills-left-container {
    flex: 0;
  }
  .skills-right-container {
    flex: 0;
  }
  .skills-title {
    font-size: 2.5rem;
    text-align: center;
    transform: translateX(-10px);
  }
  .skills-text {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    transform: translateX(-12px);
  }
  .skills-wrapper {
    width: 10%;
    min-width: 400px;
    position: absolute;
    transform: translate(-465px, 820px);
    left: 0;
    top: 0;
  }
  .skills-bar-title {
    margin-bottom: 25px;
    margin-top: 0;
  }
}

@media only screen and (min-width: 501px) and (max-width: 750px) {
  .skills {
    top: 133vh;
    padding-left: 28px;
    padding-right: 0;
    padding-bottom: 0;
    flex-direction: column;
  }
  .skills-left-container {
    flex: 0;
  }
  .skills-right-container {
    flex: 0;
  }
  .skills-title {
    font-size: 3rem;
    text-align: center;
    transform: translateX(-10px);
  }
  .skills-text {
    text-align: center;
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    transform: translateX(-12px);
  }
  .skills-wrapper {
    width: 10%;
    min-width: 600px;
    position: absolute;
    transform: translate(-376px, 710px);
    left: 0;
    top: 0;
  }
  .skills-bar-title {
    margin-bottom: 40px;
    margin-top: 0;
  }
}

@media only screen and (min-width: 751px) and (max-width: 992px) {
  .skills {
    top: 133vh;
    padding-left: 28px;
    padding-right: 0;
    padding-bottom: 0;
    flex-direction: column;
  }
  .skills-left-container {
    flex: 0;
  }
  .skills-right-container {
    flex: 0;
  }
  .skills-title {
    font-size: 3rem;
    text-align: center;
    transform: translateX(-10px);
  }
  .skills-text {
    text-align: center;
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    transform: translateX(-12px);
  }
  .skills-wrapper {
    width: 10%;
    min-width: 700px;
    position: absolute;
    transform: translate(-385px, 700px);
    left: 0;
    top: 0;
  }
  .skills-bar-title {
    margin-bottom: 40px;
    margin-top: 0;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .skills {
    display: flex;
    top: 198vh;
    padding-left: 28px;
    padding-right: 0;
    padding-bottom: 0;
    flex-direction: column;
  }
  .skills-left-container {
    flex: 50%;
    margin-right: 30rem;
  }
  .skills-right-container {
    flex: 50%;
  }
  .skills-title {
    font-size: 3rem;
    margin-left: 3.5rem;
  }
  .skills-text {
    font-size: 1.25rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 3.5rem;
  }
  .skills-wrapper {
    transform: translate(-350px, -14rem);
    min-width: 400px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) and (min-height: 600px) and (max-height: 650px) {
  .skills {
    display: flex;
    top: 196vh;
    padding-top: 3rem;
    padding-left: 1.3rem;
    padding-right: 10rem;
    padding-bottom: 0;
    flex-direction: column;
  }
  .skills-left-container {
    flex: 50%;
    margin-right: 30rem;
  }
  .skills-right-container {
    flex: 50%;
  }
  .skills-title {
    font-size: 3rem;
    margin-left: 3.5rem;
  }
  .skills-text {
    font-size: 1.25rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 3.5rem;
  }
  .skills-wrapper {
    transform: translate(-410px, -15rem);
    width: 45%;
    min-width: 500px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1800px) {
  .skills {
    display: flex;
    top: 193vh;
    padding-left: 1.3rem;
    padding-right: 10rem;
    padding-bottom: 0;
    flex-direction: column;
  }
  .skills-left-container {
    flex: 50%;
    margin-right: 30rem;
  }
  .skills-right-container {
    flex: 50%;
  }
  .skills-title {
    font-size: 3rem;
    margin-left: 3.5rem;
  }
  .skills-text {
    font-size: 1.25rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 3.5rem;
  }
  .skills-wrapper {
    transform: translate(-410px, -15rem);
    width: 45%;
    min-width: 500px;
  }
}
