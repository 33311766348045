.about-me {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100vh;
  padding-left: 15rem;
  padding-right: 18rem;
  background-color: #3e5874b7;
}

.about-me-text-container {
  flex: 50%;
}

.about-me-title {
  color: whitesmoke;
  font-size: 3rem;
}

.about-me-text {
  color: whitesmoke;
  font-size: 1.3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.about-me-button {
  position: relative;
  display: inline-flex;
  width: 180px;
  height: 55px;
  margin: 0 15px;
  perspective: 1000px;
}

.about-me-button a {
  font-size: 19px;
  letter-spacing: 1px;
  transform-style: preserve-3d;
  transform: translateZ(-25px);
  transition: transform 0.25s;
  font-family: "Saira", sans-serif;
}

.about-me-button a:before,
.about-me-button a:after {
  position: absolute;
  content: "MY CV";
  height: 55px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #011826;
  box-sizing: border-box;
  border-radius: 5px;
}

.about-me-button a:before {
  color: whitesmoke;
  background: #011826;
  transform: rotateY(0deg) translateZ(25px);
}

.about-me-button a:after {
  color: #011826;
  transform: rotateX(90deg) translateZ(25px);
}

.about-me-button a:hover {
  transform: translateZ(-25px) rotateX(-90deg);
}

.about-me-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 50%;
  transform: rotate(90deg);
  margin-top: 2rem;
  margin-bottom: 4rem;
}

/* .scene {
  position: relative;
  width: 25%;
  height: 30vh;
  background: #01070a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
} */

/* .stars-rocket {
  width: 2px;
  height: 2px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 2px 2px #fff, -20px -20px #fff, 10px 10px #fff, -80px -30px #fff,
    100px -22px #fff, 57px 79px #fff, -26px 80px #fff, 50px -100px #fff,
    100px -50px #fff, 120px -50px #fff, 80px -80px #fff, -90px -50px #fff,
    90px 90px #fff, 50px 110px #fff, 70px 40px #fff, 99px 1px #fff;
} */

.scene .rocket {
  position: relative;
  animation: animateRocket 0.2s ease infinite;
}

@keyframes animateRocket {
  0%,
  100% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(2px);
  }
}

.scene .rocket::before {
  content: "";
  position: absolute;
  bottom: -200px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 200px;
  background: linear-gradient(#00d0ff, transparent);
}

.scene .rocket::after {
  content: "";
  position: absolute;
  bottom: -200px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 200px;
  background: linear-gradient(#00d0ff, transparent);
  filter: blur(20px);
}

@media only screen and (max-width: 768px) {
  .aos-animate {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;

    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;

    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 413px) {
  .about-me {
    top: 80vh;
    padding-left: 28px;
    padding-right: 0;
    width: 93vw;
    height: 75vh;
  }
  .about-me-title {
    font-size: 2.5rem;
    text-align: center;
    transform: translateX(-20px);
  }
  .about-me-text {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding-right: calc(35px + 0.8rem);
  }
  .about-me-button {
    margin-left: 4.5rem;
  }
  .about-me-animation {
    display: none;
  }
}

@media only screen and (min-width: 414px) and (max-width: 500px) {
  .about-me {
    top: 75vh;
    padding-left: 28px;
    padding-right: 0;
    width: 93vw;
    height: 60vh;
  }
  .about-me-title {
    font-size: 2.5rem;
    text-align: center;
    transform: translateX(-20px);
  }
  .about-me-text {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding-right: calc(35px + 0.8rem);
  }
  .about-me-button {
    margin-left: 5.5rem;
  }
  .about-me-animation {
    display: none;
  }
}

@media only screen and (min-width: 501px) and (max-width: 750px) {
  .about-me {
    top: 80vh;
    padding-left: 28px;
    padding-right: 0;
    width: 99%;
    height: 48vh;
  }
  .about-me-title {
    font-size: 3rem;
    text-align: center;
    transform: translateX(-20px);
  }
  .about-me-text {
    text-align: center;
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    padding-right: calc(35px + 0.8rem);
  }
  .about-me-button {
    margin-left: 16rem;
  }
  .about-me-animation {
    display: none;
  }
}

@media only screen and (min-width: 751px) and (max-width: 992px) {
  .about-me {
    top: 80vh;
    padding-left: 28px;
    padding-right: 0;
    width: 99%;
    height: 48vh;
  }
  .about-me-title {
    font-size: 3rem;
    text-align: center;
    transform: translateX(-20px);
  }
  .about-me-text {
    text-align: center;
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    padding-right: calc(35px + 0.8rem);
  }
  .about-me-button {
    margin-left: 18rem;
  }
  .about-me-animation {
    display: none;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .about-me {
    top: 110vh;
    padding-left: 28px;
    padding-right: 0;
    height: 78vh;
  }
  .about-me-title {
    font-size: 3rem;
    margin-left: 3.5rem;
  }
  .about-me-text {
    font-size: 1.3rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 3.5rem;
    margin-right: 5rem;
  }
  .about-me-button {
    margin-left: 3.5rem;
  }
  .about-me-animation {
    margin-left: 3.5rem;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) and (min-height: 600px) and (max-height: 650px) {
  .about-me {
    top: 110vh;
    padding-left: 28px;
    padding-right: 0;
    height: 78vh;
    padding-bottom: 3rem;
  }
  .about-me-title {
    font-size: 3rem;
    margin-left: 3.5rem;
  }
  .about-me-text {
    font-size: 1.3rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    margin-left: 3.5rem;
    margin-right: 5rem;
  }
  .about-me-button {
    margin-left: 3.5rem;
  }
  .about-me-animation {
    margin-left: 9rem;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1800px) {
  .about-me {
    top: 110vh;
    padding-left: 28px;
    padding-right: 0;
    height: 78vh;
  }
  .about-me-title {
    font-size: 3rem;
    margin-left: 3.5rem;
  }
  .about-me-text {
    font-size: 1.3rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 3.5rem;
    margin-right: 5rem;
  }
  .about-me-button {
    margin-left: 3.5rem;
  }
  .about-me-animation {
    margin-left: 9rem;
  }
}
