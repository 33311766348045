.footer {
  display: flex;
  align-items: center;
  padding: 8px 10%;
  background-color: #032340;
  border-bottom: solid 1px rgba(245, 245, 245, 0.205);
  position: absolute;
  top: 471vh;
  font-weight: 600;
  font-size: 16px;
  width: 100vw;
  color: whitesmoke;
}

.f1 {
  margin-right: 13rem;
}

.f2 {
  margin-right: 13rem;
}

.footer-circle {
  width: 75px;
  height: 75px;
  background-color: #011826b7;
  border-radius: 50%;
  transform: translate(35px, -13.5px);
  z-index: 10;
}

@media only screen and (min-width: 0px) and (max-width: 413px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10%;
    top: 940vh;
    font-size: 1rem;
    width: 80vw;
    text-align: center;
  }
  .f1 {
    margin-right: 1rem;
  }
  .f2 {
    margin-right: 1rem;
  }
  .footer-circle {
    display: none;
  }
}

@media only screen and (min-width: 414px) and (max-width: 500px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10%;
    top: 856vh;
    font-size: 1rem;
    width: 80vw;
    text-align: center;
  }
  .f1 {
    margin-right: 1rem;
  }
  .f2 {
    margin-right: 1rem;
  }
  .footer-circle {
    display: none;
  }
}

@media only screen and (min-width: 501px) and (max-width: 750px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10%;
    top: 525vh;
    font-size: 1.1rem;
    width: 80vw;
    text-align: center;
  }
  .f1 {
    margin-right: 1rem;
  }
  .f2 {
    margin-right: 1rem;
  }
  .footer-circle {
    display: none;
  }
}

@media only screen and (min-width: 751px) and (max-width: 992px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10%;
    top: 530vh;
    font-size: 1.1rem;
    width: 80vw;
    text-align: center;
  }
  .f1 {
    margin-right: 1rem;
  }
  .f2 {
    margin-right: 1rem;
  }
  .footer-circle {
    display: none;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .footer {
    display: flex;
    align-items: center;
    padding: 8px 4%;
    position: absolute;
    top: 621vh;
    font-size: 15px;
  }
  .f1 {
    margin-right: 2rem;
  }
  .f2 {
    margin-right: 2rem;
  }
  .footer-circle {
    display: none;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1366px) {
  .footer {
    display: flex;
    align-items: center;
    padding: 8px 4%;
    position: absolute;
    top: 646.3vh;
    font-size: 16px;
  }
  .f1 {
    margin-right: 3rem;
  }
  .f2 {
    margin-right: 3rem;
  }
  .footer-circle {
    display: none;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1300px) and (min-height: 600px) and (max-height: 650px) {
  .footer {
    display: flex;
    align-items: center;
    padding: 8px 4%;
    position: absolute;
    top: 703.3vh;
    font-size: 16px;
  }
  .f1 {
    margin-right: 3rem;
  }
  .f2 {
    margin-right: 3rem;
  }
  .footer-circle {
    display: none;
  }
}

@media only screen and (min-width: 1367px) and (max-width: 1800px) {
  .footer {
    display: flex;
    align-items: center;
    padding: 8px 4%;
    position: absolute;
    top: 640.9vh;
    font-size: 16px;
  }
  .f1 {
    margin-right: 3rem;
  }
  .f2 {
    margin-right: 3rem;
  }
  .footer-circle {
    display: none;
  }
}
