.upbutton-circle {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background: #053663;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(93.8vw, 80vh);
  position: fixed;
  z-index: 100;
}

.upbutton-circle i {
  font-size: 2rem;
}

.upbutton-circle:hover {
  cursor: pointer;
  background: #06417996;
}
