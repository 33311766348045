.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: "Saira", sans-serif;
  overflow-x: hidden;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background-color: #011826;
  font-weight: 400;
  overflow-x: hidden;
}

#element::-webkit-scrollbar {
  display: none;
}
